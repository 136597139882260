export const daysOfWeek = [
  {
    label: "Monday",
    key: "Monday",
  },
  {
    label: "Tuesday",
    key: "Tuesday",
  },
  {
    label: "Wednesday",
    key: "Wednesday",
  },
  {
    label: "Thursday",
    key: "Thursday",
  },
  {
    label: "Friday",
    key: "Friday",
  },
  {
    label: "Saturday",
    key: "Saturday",
  },
  {
    label: "Sunday",
    key: "Sunday",
  },
];
