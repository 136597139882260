// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[class*=text-color-blue] {
  color: #2196f3;
}

[class*=text-color-red] {
  color: #e53935;
}

[class*=text-color-gray] {
  color: #e5e5e5;
}

[class*=background-color-blue] {
  background-color: #2196f3;
}

[class*=background-color-red] {
  background-color: #e53935;
}

[class*=background-color-gray] {
  background-color: #e5e5e5;
}

[class*=border-color-blue] {
  border-color: #2196f3 !important;
}

[class*=border-color-red] {
  border-color: #e53935 !important;
}

[class*=border-color-gray] {
  border-color: #e5e5e5 !important;
}

.center-item-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ag-row .ag-cell {
  display: flex;
  align-items: center;
  white-space: normal;
  line-height: 16px;
  font-size: 14px;
}

.ag-root-wrapper {
  --ag-wrapper-border-radius: 10px;
}

.swal2-container {
  z-index: 1303 !important;
}

.sw-2-container-alert .sw-2-popup {
  width: 400px;
  height: 280px;
  padding: 10px;
  border-radius: 12px;
  align-items: center;
}
.sw-2-container-alert .swal2-image {
  margin: 0 auto;
}
.sw-2-container-alert .sw-2-title {
  font-size: 16px !important;
  font-weight: 700;
  margin-top: 0;
  padding: 0;
  height: 1em;
}
.sw-2-container-alert .sw-2-text {
  margin: 0 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.sw-2-container-alert .sw-2-action-container {
  margin: 0;
  width: 100%;
  flex-direction: row-reverse;
}
.sw-2-container-alert .sw-2-comfirm-button {
  flex: 1;
  height: 46px;
}
.sw-2-container-alert .sw-2-cancel-button {
  flex: 1;
  height: 46px;
  background-color: transparent !important;
}

.sw-2-container-toast {
  background-color: transparent !important;
}
.sw-2-container-toast .sw-2-toast {
  width: 470px;
  height: 52px;
  padding: 0;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.sw-2-container-toast .sw-2-toast .sw-2-toast-tile {
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #ffffff;
}
.sw-2-container-toast .sw-2-toast .sw-2-toast-icon {
  width: 20px;
  height: 20px;
  margin: 15px;
}/*# sourceMappingURL=global.css.map */`, "",{"version":3,"sources":["webpack://./src/style/global.scss","webpack://./src/style/global.css"],"names":[],"mappings":"AAOE;EACE,cARK;ACET;;ADKE;EACE,cARK;ACMT;;ADCE;EACE,cARK;ACUT;;ADGE;EACE,yBAdK;ACcT;;ADDE;EACE,yBAdK;ACkBT;;ADLE;EACE,yBAdK;ACsBT;;ADHE;EACE,gCAAA;ACMJ;;ADPE;EACE,gCAAA;ACUJ;;ADXE;EACE,gCAAA;ACcJ;;ADVA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;ACaF;;ADVA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,iBAAA;EACA,eAAA;ACaF;;ADVA;EACE,gCAAA;ACaF;;ADVA;EACE,wBAAA;ACaF;;ADTE;EACE,YAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;ACYJ;ADVE;EACE,cAAA;ACYJ;ADVE;EACE,0BAAA;EACA,gBAAA;EACA,aAAA;EACA,UAAA;EACA,WAAA;ACYJ;ADVE;EACE,oBAAA;EACA,0BAAA;EACA,2BAAA;ACYJ;ADVE;EACE,SAAA;EACA,WAAA;EACA,2BAAA;ACYJ;ADVE;EACE,OAAA;EACA,YAAA;ACYJ;ADVE;EACE,OAAA;EACA,YAAA;EACA,wCAAA;ACYJ;;ADRA;EACE,wCAAA;ACWF;ADTE;EACE,YAAA;EACA,YAAA;EACA,UAAA;EACA,wBAAA;EACA,mBAAA;EACA,mBAAA;EACA,2BAAA;ACWJ;ADVI;EACE,UAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;ACYN;ADVI;EACE,WAAA;EACA,YAAA;EACA,YAAA;ACYN,CAAA,qCAAA","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
