import React from "react";
import { TablePagination } from "@mui/material";

export default function CMSPagination(props) {
  const { count, page, onPageChange, rowsPerPage, onRowsPerPageChange } = props;

  return (
    <TablePagination
      component="div"
      count={count}
      page={page}
      onPageChange={onPageChange}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={onRowsPerPageChange}
    />
  );
}
