import Swal from "sweetalert2";

import error from "../assets/toast/error.svg";
import success from "../assets/toast/success.svg";

const alertStyle = (status) => {
  switch (status) {
    case "error":
      return { icon: error, color: "#D32F2F" };

    case "warning":
      return { icon: error, color: "#DC6803" };

    default:
      return { icon: success, color: "#4CAF50" };
  }
};

export default function CMSToast(getProps) {
  const defaultProps = {
    status: "success", // success, error, warning
    title: "",
  };
  const { title, status } = {
    ...defaultProps,
    ...getProps,
  };
  const check = alertStyle(status);

  const Toast = Swal.mixin({
    imageUrl: check.icon,
    position: "top",
    iconColor: "white",
    timer: 2000,
    showConfirmButton: false,
    title: "Location was successfully added.",
    html: `<style>.sw-2-toast{background-color: ${check.color} !important;}</style>`,

    customClass: {
      container: "sw-2-container-toast",
      popup: "sw-2-toast",
      image: "sw-2-toast-icon",
      title: "sw-2-toast-tile",
    },
  });

  Toast.fire({
    title: title,
  });

  return;
}
