import React from "react";
import Swal from "sweetalert2";

import ErrorSvg from "../assets/popupIcon/error.svg";
import WarningrSvg from "../assets/popupIcon/warning.svg";

const findIconInAlert = (iconType) => {
  let result = WarningrSvg;
  // if (iconType === "warning") {
  //   return WarningrSvg;
  // }
  if (iconType === "error") {
    return ErrorSvg;
  }
  return result;
};

export default function ModalAlert(getProps) {
  const defaultProps = {
    iconType: "warning",

    title: "",
    description: "",

    confirmButtonColor: "#2196f3",
    onComfirmClick: false,
    textInComfirmButton: "Ok",

    cancelButton: false,
    // cancelButtonColor: "#FF0800",
    onCancelClick: false,
    textInCancelButton: "Cancel",
  };
  const {
    iconType, // warning, error

    title,
    description,

    confirmButtonColor,
    onComfirmClick, //deprecate
    onConfirmClick,
    textInComfirmButton,

    cancelButton,
    // cancelButtonColor,
    textInCancelButton,
    onCancelClick,
  } = {
    ...defaultProps,
    ...getProps,
  };

  const handleConfirm = async () => {
    if (onConfirmClick) {
      await onConfirmClick();
    }

    if (onComfirmClick) {
      await onComfirmClick();
    }
  };

  const handleCancel = () => {
    if (onCancelClick) {
      onCancelClick();
    }
  };

  Swal.fire({
    imageUrl: findIconInAlert(iconType),

    title: title,
    text: description,

    confirmButtonText: textInComfirmButton,
    cancelButtonText: textInCancelButton,

    showCancelButton: cancelButton,
    confirmButtonColor: confirmButtonColor,

    allowOutsideClick: false,
    customClass: {
      container: "sw-2-container-alert",
      popup: "sw-2-popup",
      title: "sw-2-title",
      htmlContainer: "sw-2-text",

      actions: "sw-2-action-container",
      confirmButton: "sw-2-comfirm-button",
      cancelButton: "sw-2-cancel-button",
    },
    html: `${description}<style>.sw-2-cancel-button{border: 1px solid ${confirmButtonColor} !important; color: ${confirmButtonColor} !important;}</style>`,
  }).then(async (result) => {
    if (result?.isConfirmed) {
      await handleConfirm();
    }
    if (result.isDismissed) handleCancel();
  });
  return;
}
