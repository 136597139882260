export const optionCategoryStatus = [
  {
    label: "Inactive",
    value: "Inactive",
    backgroundColor: "#FAFAFA",
    textColor: "#616161",
  },
  {
    label: "Active",
    value: "Active",
    backgroundColor: "#E8F5E9",
    textColor: "#388E3C",
  },
  {
    label: "Expired",
    value: "Expired",
    backgroundColor: "#fae8e8",
    textColor: "#f57a73",
  },
];
