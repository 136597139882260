import React from "react";
import { Button } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";

export default function CMSButton(props) {
  const {
    onClickButton = () => {},

    buttonStartIcon = <></>,
    buttonEndIcon = <></>,
    buttonVariant = "contained", // contained, outlined, text

    buttonType = "button",

    buttonText = "Text",
    textColor = "",

    buttonColor = "#2196f3",

    disabledButton = false,

    // borderColor = "",
    style,
    height,
  } = props;
  const themeButton = createTheme({
    palette: {
      primary: {
        main: buttonColor,
      },
    },
  });
  return (
    <ThemeProvider theme={themeButton}>
      <CssBaseline />
      <Button
        variant={buttonVariant}
        sx={{
          ...style,
          height: height || 46,
          minWidth: 80,
          color: textColor,
          boxShadow: "none",
          textTransform: "none",
        }}
        startIcon={buttonStartIcon}
        endIcon={buttonEndIcon}
        type={buttonType}
        onClick={() => {
          onClickButton();
        }}
        disabled={disabledButton}
      >
        {buttonText}
      </Button>
    </ThemeProvider>
  );
}
