export const CMSRegexNumber = (num) => {
  let cleanNum = parseInt(num.replace(/,/g, ""));

  if (isNaN(cleanNum)) {
    return "";
  }
  return cleanNum.toLocaleString();
};


export const CMSRegexDecimal = (num) => {
  let cleanNum = num.replace(/[^0-9.,]/g, ""); 

  
  const parts = cleanNum.split(".");
  if (parts.length > 2) {
    cleanNum = parts[0] + "." + parts[1]; // Keep only one decimal point
  }

  // Limit to two decimal places
  if (parts[1]?.length > 2) {
    cleanNum = parts[0] + "." + parts[1].slice(0, 2);
  }

  // Convert to number and format with commas
  let formattedNum = parseFloat(cleanNum);
  if (isNaN(formattedNum)) return "";

  return  cleanNum.toLocaleString();

};




