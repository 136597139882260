import React from "react";
import { Grid, Backdrop } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

export default function CMSLoading(props) {
  const { open = false } = props;
  return (
    <Backdrop
      open={open}
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "100vh" }}
      >
        <Grid item xs={3}>
          <CircularProgress />
        </Grid>
      </Grid>
    </Backdrop>
  );
}
