import React from "react";
import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

export default function CMSTable(props) {
  const {
    rowData = [],
    columnDefs = [],
    onSortChangedFunc,
    gridRef = React.useRef(),
    gridOptions,
  } = props;

  return (
    <div className="ag-theme-alpine" style={{ width: "100%", height: "100%" }}>
      <AgGridReact
        ref={gridRef}
        rowData={rowData}
        columnDefs={columnDefs.map((row) => {
          if (!row.valueFormatter) {
            return {
              ...row,
              valueFormatter: (params) => params.value || "-",
            };
          } else {
            return row;
          }
        })}
        suppressDragLeaveHidesColumns
        onSortChanged={onSortChangedFunc}
        gridOptions={{
          ...gridOptions,
          rowHeight: 70,
          alwaysMultiSort: true,
        }}
        defaultColDef={{
          flex: 1,
          sortable: true,
          resizable: false,
          valueFormatter: "-",
        }}
        overlayNoRowsTemplate={`
        <div 
          style="
          display: flex;
          flex-direction: column;
          align-items: center;"
        >
          <img src="https://cdn.sawasdee.ai/cms_tale_style_guide/3irtpslqdevtrr.png" height="118px" width="152"/>
          <p style="font-size: 16px; font-weight: 700;">No content</p>
          <p style="font-size: 14px; margin: 0;">You can create a content using the button.</p>
        </div>`}
      />
    </div>
  );
}
