import { Box } from "@mui/material";
import { CMSButton, CMSInput } from "../gtt-cms-style-guide.js";
import ShareIcon from "@mui/icons-material/Share";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export default function CMSSticky(getProps) {
  const defaultProps = {
    style: null,
    styleButton: null,
    buttonTextSave: "SAVE",
    buttonTextCancel: "CANCEL",
    buttonTextDelete: "DELETE",
    valueButtonSwitch: [""],
    disabledButtonCreate: false,
    disabledButtonCancel: false,
    disabledButtonDelete: false,
    disabledButtonShare_link: false,
    disabledButtonSwitch: [false],
    disabledButtonNext: false,
    disabledButtonBack: false,
    labelButtonSwitch: [""],
    hideButtonSwitch: false,
    hideButtonDelete: false,
    hideButtonCancel: false,
    hideButtonCreate: false,
    hideButtonShare_link: true,
    hideButtonNext: true,
    hideButtonBack: true,
    onChangeActive: [() => {}],
    handleSaveClick: null,
    handleCancelClick: null,
    handleDelete: null,
    handleShare_link: null,
    handleBackStep: null,
  };

  const {
    style,
    styleButton,
    buttonTextSave,
    buttonTextCancel,
    buttonTextDelete,
    hideButtonSwitch,
    hideButtonDelete,
    hideButtonCancel,
    hideButtonCreate,
    hideButtonShare_link,
    hideButtonNext,
    hideButtonBack,
    valueButtonSwitch,
    disabledButtonCreate,
    disabledButtonCancel,
    disabledButtonDelete,
    disabledButtonShare_link,
    disabledButtonSwitch,
    disabledButtonNext,
    disabledButtonBack,
    labelButtonSwitch,
    onChangeActive,
    handleSaveClick,
    handleCancelClick,
    handleDelete,
    handleShare_link,
    handleBackStep,
  } = { ...defaultProps, ...getProps };

  const handleClick = (fn) =>
    fn
      ? fn()
      : console.error("Error: The provided argument is not a function.");

  if (!Array.isArray(valueButtonSwitch)) {
    console.error("Error: `valueButtonSwitch` must be an array.");
  }

  if (!Array.isArray(labelButtonSwitch)) {
    console.error("Error: `labelButtonSwitch` must be an array.");
  }

  if (!Array.isArray(disabledButtonSwitch)) {
    console.error("Error: `disabledButtonSwitch` must be an array.");
  }

  if (
    !Array.isArray(onChangeActive) ||
    !onChangeActive.every((fn) => typeof fn === "function")
  ) {
    console.error("Error: `onChangeActive` must be an array of functions.");
  }

  return (
    <Box
      sx={{
        ...style,
        bottom: 0,
        zIndex: 10,
        height: "80px",
        position: "sticky",
        marginTop: "auto",
        backgroundColor: "#fff",
        borderTop: "1px solid #e5e5e5",
      }}
    >
      <Box
        sx={{
          px: 2,
          gap: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          height: "80px",
        }}
      >
        {!hideButtonBack && (
          <Box mr="auto">
            <CMSButton
              style={styleButton}
              buttonText={"BACK"}
              buttonVariant="outlined"
              disabledButton={disabledButtonBack}
              buttonStartIcon={<ArrowBackIosNewIcon />}
              onClickButton={() => handleClick(handleBackStep)}
            />
          </Box>
        )}

        {!hideButtonSwitch && (
          <Box sx={{ display: "flex", gap: 1, mb: 3 }}>
            {valueButtonSwitch.map((value, index) => (
              <Box key={index}>
                <CMSInput
                  value={value}
                  onChangeInput={(e) => onChangeActive[index]?.(e)}
                  inputType="switch"
                  labelFor={false}
                  label={labelButtonSwitch[index] || "Toggle"}
                  disableForm={disabledButtonSwitch[index] || false}
                />
              </Box>
            ))}
          </Box>
        )}

        {!hideButtonDelete && (
          <CMSButton
            style={styleButton}
            buttonColor="#D32F2F"
            buttonText={buttonTextDelete}
            buttonVariant="outlined"
            onClickButton={() => handleClick(handleDelete)}
            disabledButton={disabledButtonDelete}
          />
        )}
        {!hideButtonShare_link && (
          <CMSButton
            style={styleButton}
            buttonText="SHARE LINK"
            buttonVariant="outlined"
            buttonStartIcon={<ShareIcon />}
            onClickButton={() => handleClick(handleShare_link)}
            disabledButton={disabledButtonShare_link}
          />
        )}
        {!hideButtonCancel && (
          <CMSButton
            style={styleButton}
            buttonText={buttonTextCancel}
            buttonVariant="outlined"
            onClickButton={() => handleClick(handleCancelClick)}
            disabledButton={disabledButtonCancel}
          />
        )}
        {!hideButtonCreate && (
          <CMSButton
            style={styleButton}
            buttonText={buttonTextSave}
            buttonType="button"
            onClickButton={() => handleClick(handleSaveClick)}
            disabledButton={disabledButtonCreate}
          />
        )}
        {!hideButtonNext && (
          <CMSButton
            style={styleButton}
            buttonType={"submit"}
            buttonText={"NEXT"}
            disabledButton={disabledButtonNext}
            buttonEndIcon={<ArrowForwardIosIcon />}
          />
        )}
      </Box>
    </Box>
  );
}
