import React from "react";
import { Box } from "@mui/material";
import { daysOfWeek } from "../config/data/date";

export default function CMSLangTab(props) {
  const { value, onChangeValue, dataType = "", option } = props;
  let loopData = JSON.parse(localStorage.getItem("langSet"));
  if (option) loopData = option;
  if (dataType === "date") loopData = daysOfWeek;
  return (
    <Box sx={{ display: "flex", py: 2, cursor: "pointer" }}>
      {loopData.map((row, index) => {
        const active = value === row.key;
        return (
          <Box
            key={index}
            sx={{ px: 2, py: 1, borderBottom: active ? 2 : 0 }}
            onClick={() => onChangeValue(row.key)}
            className={`${active ? "border-color-blue text-color-blue" : ""}`}
          >
            {row.key.toUpperCase()}
          </Box>
        );
      })}
    </Box>
  );
}
